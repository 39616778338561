@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: Staatliches;
  src: url('../static/fonts/Staatliches-Regular.ttf') format('truetype');

  font-family: Simplifica;
  src: url('../static/fonts/SIMPLIFICA.ttf') format('truetype');
}

@layer base {
  .font-outline-1 {
    -webkit-text-stroke: 1px black;
    paint-order: stroke fill;
  }
  .font-outline-2 {
    -webkit-text-stroke: 2px black;
    paint-order: stroke fill;
  }
  .font-outline-4 {
    -webkit-text-stroke: 4px black;
    paint-order: stroke fill;
  }
}
